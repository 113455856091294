@import '../../styles/_mixin.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  justify-content: center;
  align-items: center;
}

.modal_wrapper {
  outline: 'none';
  position: absolute;
  // width: 80vw;
  // height: 80vh;
  max-width: 820px;
  max-width: 80vw;
  min-height: 550px;
  max-height: 80vh;
  padding: 20px 60px;

  @include desktop {
    width: 80vw;
  }

  @include tablet {
    width: 80vw;
  }

  @include mobile {
    width: 80vw;
    height: 60vh;
  }

  // max-width: 1200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  outline: 0;

  border-radius: 20px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(7px);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 20px;
    cursor: pointer;
  }

  .container {
    width: 100%;
    height: 100%;

    padding: 40px 0;

    // overflow: scroll;
    overflow: hidden;

    @include mobile {
      overflow: scroll;
    }

    h1 {
      color: rgba(18, 18, 18, 0.8);
      font-family: 'Spoqa Han Sans Neo';
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px; /* 90% */
      margin-bottom: 28px;
    }

    .paragraph {
      margin-bottom: 32px;
      strong {
        color: rgba(18, 18, 18, 0.8);
        font-family: 'Spoqa Han Sans Neo';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 112.5% */
      }

      p {
        color: rgba(18, 18, 18, 0.8);
        font-family: 'Spoqa Han Sans Neo';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        margin: 0;
      }
    }
  }
}
