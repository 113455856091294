@import '../../../styles/_mixin.scss';

.wrapper {
  width: 100vw;
  height: 100vh;
  background: #ededed;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .notice_container {
    width: 100%;
    max-height: calc(100vh - 116px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include mobile {
      padding-top: 100px;
    }

    .header {
      .title {
        color: #121212;
        font-family: Pretendard;
        font-size: 46px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-align: center;

        @include tablet {
          font-size: 36px;
        }
        @include mobile {
          font-size: 36px;
        }
      }

      .subtitle {
        color: #121212;
        font-family: Pretendard;
        font-size: 22px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-align: center;

        @include tablet {
          font-size: 18px;
        }
        @include mobile {
          font-size: 18px;
        }
      }

      margin-bottom: 80px;

      @include desktop {
        margin-bottom: 40px;
      }
      @include mobile {
        margin-bottom: 40px;
      }
    }

    .content_wrapper {
      width: 1024px;
      max-width: 1024px;

      @include tablet {
        width: 95%;
      }
      @include mobile {
        width: 90%;
      }

      .row {
        width: 100%;
        height: 80px;

        @include desktop {
          height: 65px;
        }
        @include tablet {
          height: 65px;
        }

        display: flex;
        flex-direction: row;
        align-items: center;

        font-family: Pretendard;
        font-size: 18px;

        @include mobile {
          flex-direction: column;
          align-items: flex-start;
        }

        &:hover {
          background: rgba(18, 18, 18, 0.04);
        }

        &:active {
          background: rgba(18, 18, 18, 0.07);
        }

        .date {
          width: 180px;

          text-align: center;
          color: #5f5f5f;
          font-weight: 300;

          @include mobile {
            text-align: left;
            font-size: 14px;
          }
        }

        .title {
          // width: calc(100% - 180px - 100px);

          color: #121212;
          font-size: 18px;
          font-weight: 500;

          cursor: pointer;

          @include mobile {
            font-size: 14px;
          }
        }

        .icons {
          width: 100px;
          display: flex;
          gap: 12px;

          img {
            width: 24px;
            height: 24px;
            cursor: pointer;
          }
        }
      }

      .not_found {
        color: #121212;
        font-family: Pretendard;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
      }

      .IR_container {
        .title {
          display: flex;
          flex-direction: row;
          gap: 9px;

          .title_back_icon {
            width: 24px;
            cursor: pointer;
          }

          .title_text {
            color: #121212;
            font-family: Pretendard;
            font-size: 36px;

            @include mobile {
              font-size: 24px;
            }

            font-style: normal;
            font-weight: 300;
            line-height: normal;
          }
        }

        .date {
          color: #5f5f5f;
          font-family: Pretendard;
          font-size: 18px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;

          margin-top: 32px;
          margin-bottom: 32px;
        }

        .content {
          color: #121212;
          font-family: Pretendard;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 26px; /* 144.444% */

          margin-bottom: 48px;
        }

        .content_footer {
          text-align: center;

          p {
            margin: 0;

            color: #121212;
            font-family: Pretendard;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px; /* 144.444% */
          }
        }
      }
    }

    .pagination {
      width: 100%;
      height: 80px;

      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;

      .page {
        display: flex;
        justify-content: center;
        align-items: center;

        color: rgba(18, 18, 18, 0.6);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;

        cursor: pointer;

        &:active {
          color: #121212;
        }
      }

      .active {
        color: #121212;
      }

      img {
        width: 18px;
        height: 18px;
      }
    }
  }
}
