// Breakpoints
$breakpoint-mobile: 280px;
$breakpoint-tablet: 720px;
$breakpoint-desktop: 1024px;
$breakpoint-4k: 1440px;

@mixin mobile {
  @media (min-width: #{$breakpoint-mobile}) and (max-width: #{$breakpoint-tablet - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$breakpoint-tablet}) and (max-width: #{$breakpoint-desktop - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$breakpoint-desktop}) and (max-width: #{$breakpoint-4k - 1px}) {
    @content;
  }
}
