@import '../styles/_mixin.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // height: calc(100vh - 50px);
  width: 100vw;
  height: calc(100vh - 100px);
  // overflow: hidden;

  background-color: #111;

  .img_container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    // z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.wrapper_mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: calc(100vh - 100px);

  .img_container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    // z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.slogan {
  position: absolute;
  z-index: 2;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  color: #f6f5e3;
  text-align: center;
  font-family: Pretendard;
  font-size: 34px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;

  @include desktop {
    font-size: 24px;
  }

  @include tablet {
    font-size: 24px;
  }

  @include mobile {
    width: 100%;
    font-size: 18px;
  }
}

.slider_footer {
  width: 100vw;
  height: 50px;

  // background-color: #111;
  position: absolute;
  z-index: 2;

  left: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  @include mobile {
    gap: 10px;
  }

  .slider_footer_item_wrapper {
    cursor: pointer;
    height: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    .slider_footer_item {
      width: 48px;
      height: 1px;
      border-radius: 2px;
      background: #798f9c;

      @include mobile {
        width: 24px;
      }
    }
    .active {
      height: 2px;
      background: #fff;
    }
  }
}

.wrapper_philosophy {
  width: 100vw;
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  color: #f6f5e3;
  text-align: center;
  font-family: Julius Sans One;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  overflow: hidden;

  .philosophy_title {
    font-size: 30px;
    margin-top: 240px;

    @include desktop {
      font-size: 24px;
    }
    @include tablet {
      font-size: 24px;
    }
    @include mobile {
      font-size: 18px;
    }
  }

  .philosophy_paragraph {
    font-size: 22px;
    margin: 32px 150px 140px 150px;
    max-width: 870px;
    text-align: left;
    line-height: 1.2;

    &:nth-of-type(4) {
      margin-bottom: 140px;
    }

    @include desktop {
      font-size: 18px;
    }
    @include tablet {
      font-size: 18px;
    }
    @include mobile {
      margin: 32px 20px 120px 20px;
      font-size: 14px;
    }
  }

  .philosophy_left {
    width: 100vw;
    text-align: left;
  }

  .philosophy_background {
    // background-color: rgba(0, 0, 0, 0.9);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.35) 0%,
      rgba(0, 0, 0, 1) 10%,
      rgba(0, 0, 0, 1) 100%
    );
    position: fixed;
    width: 100vw;
    height: 500vh;

    z-index: -1;
    top: 0;
    left: 0;
  }
}

.img_container {
  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-rows: 0.2385vw, 0.11925vw, 0.11925vw, 0.0732vw, 0.2928vw;
  gap: 16px;
  width: 75vw;

  .img_a {
    grid-row-start: 1;
    grid-row-end: 3;
    // grid-row: 1 / 4;
  }

  .img_b {
    grid-row-start: 1;
    grid-row-end: 2;
    transform: translateY(-32px);
  }

  .img_c {
    grid-row-start: 2;
    grid-row-end: 3;
    transform: translateY(-64px);
  }

  .img_d {
    grid-row-start: 3;
    grid-row-end: 6;
    grid-column-start: 1;
    grid-column-end: 2;
    transform: translateY(-16px);
  }

  .img_e {
    grid-column: 2 / 3;
    grid-row: 3 / 5;
    transform: translateY(-96px);
  }
}

.img_container_mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .img_a {
    max-width: 250px;
  }
  .img_d {
    max-width: 250px;
  }
}

.wrapper_credit {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: flex-end;
  width: 100vw;
  height: 98.65vw;

  background: url('../assets/credit_background.png') no-repeat center;
  background-size: cover;

  @include tablet {
    background: url('../assets/credit_background_mobile.png') no-repeat center;
    height: 200vw;
  }
  @include mobile {
    background: url('../assets/credit_background_mobile.png') no-repeat center;
    height: 247vw;
    background-size: contain;
  }

  .footer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    padding: 120px 220px;

    @include desktop {
      padding: 60px 110px;
    }
    @include tablet {
      padding: 60px 40px;
    }
    @include mobile {
      padding: 20px 20px;
    }

    .left {
      display: flex;
      flex-direction: column;
      gap: 12px;
      flex: 1;
      @include mobile {
        flex: 2;
      }

      img {
        width: 170px;
        margin-bottom: 20px;
        filter: invert(1);

        @include mobile {
          width: 100px;
        }
      }

      p {
        margin: 0;
        padding: 0;

        color: #121212;
        font-family: 'Spoqa Han Sans Neo';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @include tablet {
          font-size: 14px;
        }
        @include mobile {
          font-size: 12px;
        }
      }
    }

    .right {
      flex: 1;

      display: flex;
      align-items: center;
      justify-content: flex-end;

      height: 20px;

      gap: 16px;

      @include mobile {
        flex-direction: column;
        align-items: flex-end;
      }

      .menu {
        color: #121212;
        text-align: center;
        font-family: 'Spoqa Han Sans Neo';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        cursor: pointer;

        @include tablet {
          font-size: 14px;
        }
      }

      .vertical_divider {
        width: 2px;
        height: 12px;

        border-radius: 1px;
        background: #121212;
      }
    }
  }
}

.artists_container {
  background-color: blue;
  height: 100vh;
  background-image: url('../assets/workplace_with_gradient.png');
  background-size: cover;

  display: flex;
  flex-direction: column;

  .flex {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // justify-content: flex-start;
    height: 100%;
    // background-color: yellow;

    padding-left: 120px;
    padding-bottom: 120px;
    // padding-top: 120px;
    justify-content: flex-end;

    @include tablet {
      padding-left: 30px;
      // padding-top: 30px;
    }
    @include mobile {
      padding: 20px;
      justify-content: flex-end;
    }

    .title {
      color: #ededed;
      font-family: 'Julius Sans One';
      font-size: 83px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 32px;

      @include desktop {
        font-size: 63px;
      }
      @include tablet {
        font-size: 63px;
      }
      @include mobile {
        font-size: 36px;
      }
    }

    .content {
      max-width: 750px;

      color: #ededed;
      font-family: 'Julius Sans One';
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 34px; /* 121.429% */

      @include desktop {
        font-size: 18px;
      }
      @include tablet {
        font-size: 18px;
      }
      @include mobile {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}
