.card_container {
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
  gap: 6px;
  max-width: 300px;
  padding: 20px;

  .profile_image {
    border: 2px solid #7b7b7b;
  }

  .profile_blank {
    width: 300px;
  }

  color: #121212;
  font-family: Pretendard;
  font-style: normal;
  line-height: normal;

  .name {
    padding: 10px;
    font-size: 22px;
    font-weight: 500;
  }

  .bio {
    padding: 10px;
    // white-space: pre;
    font-size: 18px;
    font-weight: 300;
  }
}
