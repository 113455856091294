@import '../../styles/_mixin.scss';

.header {
  // height: 72px;
  // border-bottom: 1px solid #e5e5e5;
  padding-top: 32px;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 5000;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .logo_wrapper {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .logo {
      margin-left: 60px;
      @include mobile {
        margin-left: 20px;
        width: 150px;
      }
      cursor: pointer;
    }
  }

  .button_wrapper {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 60px;
    @include mobile {
      padding-right: 20px;
    }
    gap: 30px;

    .button_menu {
      width: 24px;
      height: 24px;

      display: flex;
      justify-content: center;
      align-items: center;
      gap: 26px;

      color: #fff;

      text-align: center;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 200;
      line-height: normal;

      cursor: pointer;
    }

    .button_recruit.dark {
      color: rgba(255, 255, 255, 0.8);
    }

    .button_recruit.light {
      color: rgba(255, 255, 255, 1);
    }
  }
}

.button_container {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  .button_icon {
    width: 16px;
    height: 16px;
  }

  .button_label {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .dark {
    color: #fff;
  }

  .light {
    color: #181616;
  }
}

.button_menu_wrapper {
  position: absolute;
  top: 72px;
  right: 10px;

  width: 120px;
  // height: 100px;
  // background-color: yellow;

  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    // color: #fff;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:hover {
      font-weight: 600;
    }

    cursor: pointer;
  }
}
