@import '../../styles/_mixin.scss';

.wrapper {
  width: 100vw;
  background-color: #ededed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 300px 0;

  @include mobile {
    padding: 100px 0;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 40px;
    max-width: 1640px;

    // .row {
    //   display: flex;
    //   flex-wrap: wrap;
    //   justify-content: center;
    //   padding: 0 40px;
    //   max-width: 1640px;
    // }
  }
}
