@import '../../../styles/_mixin.scss';

.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ededed;

  .contact_container {
    @include mobile {
      padding-top: 100px;
    }
    .header {
      .title {
        color: #121212;
        font-family: Pretendard;
        font-size: 46px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-align: center;

        @include desktop {
          font-size: 36px;
        }

        @include tablet {
          font-size: 36px;
        }
        @include mobile {
          font-size: 24px;
        }
      }

      .subtitle {
        color: #121212;
        font-family: Pretendard;
        font-size: 22px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-align: center;

        @include desktop {
          font-size: 18px;
        }
        @include tablet {
          font-size: 18px;
        }
        @include mobile {
          font-size: 14px;
        }
      }

      margin-bottom: 80px;

      @include desktop {
        margin-bottom: 20px;
      }
      @include tablet {
        margin-bottom: 20px;
      }
      @include mobile {
        margin-bottom: 20px;
      }
    }

    .form {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;

      input {
        border: 0;
        background-color: #ededed;
        border-bottom: 1px solid #5e5e5e;
        margin-bottom: 20px;

        width: 445px;
        height: 42px;

        @include desktop {
          height: 28px;
        }

        @include tablet {
          height: 28px;
        }

        @include mobile {
          width: 85vw;
          height: 16px;
        }

        // padding: 10px 0 0 0;

        &::placeholder {
          color: rgba(18, 18, 18, 0.3);

          /* Text-m/Regular */
          font-family: 'Spoqa Han Sans Neo';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 128.571% */

          @include mobile {
            font-size: 12px;
          }
        }

        &:focus {
          outline: none;
        }
      }

      input.error {
        border-bottom: 1px solid #ff0000;
      }

      input[type='checkbox'] {
        width: 15px;
        height: 15px;
        margin-right: 10px;

        @include mobile {
          margin: 0;
        }
      }

      .checkbox {
        display: inline-block;
        position: relative;
        vertical-align: top;
        // display: flex;
        // align-items: center;

        color: rgba(18, 18, 18, 0.8);

        /* Text-m/Regular */
        font-family: 'Spoqa Han Sans Neo';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */

        @include mobile {
          display: flex;
          flex-direction: column;
          align-items: center;

          margin-bottom: 10px;
        }

        .link {
          color: rgba(86, 73, 228, 0.8);
          font-family: 'Spoqa Han Sans Neo';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 128.571% */
          text-decoration-line: underline;

          cursor: pointer;

          &:hover {
            color: #5649e4;
          }
        }
      }

      input[type='submit'] {
        width: 91px;
        height: 43px;
        flex-shrink: 0;

        border-radius: 30px;
        border: 1px solid #a0a0a0;
        background: #e5e5e5;

        color: #5e5e5e;
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &:hover {
          border-radius: 30px;
          border: 1px solid rgba(18, 18, 18, 0.6);
          background: #cecece;

          color: #121212;
          font-family: Pretendard;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        &:active {
          border-radius: 30px;
          border: 1px solid rgba(18, 18, 18, 0.8);
          background: #bdbdbd;

          color: #121212;
          font-family: Pretendard;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        cursor: pointer;
      }
    }

    .company_introduction {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        color: #000;
        font-family: Pretendard;
        font-size: 20px;
        font-style: normal;
        font-weight: 200;
        line-height: normal;

        margin-bottom: 32px;

        @include desktop {
          margin-bottom: 24px;
        }

        @include tablet {
          margin-bottom: 24px;
        }

        @include mobile {
          font-size: 16px;
          margin-bottom: 12px;
        }
      }

      .button_wrapper {
        display: flex;
        gap: 10px;

        .button {
          width: 122px;
          height: 44px;
          display: inline-flex;
          // padding: 12px 32px;
          justify-content: center;
          align-items: center;
          gap: 8px;

          border-radius: 12px;
          border: 1px solid rgba(25, 24, 24, 0.3);
          background: rgba(224, 224, 224, 0.8);

          cursor: pointer;

          @include mobile {
            width: 80px;
            height: 32px;
          }

          span {
            color: rgba(18, 18, 18, 0.8);
            font-family: 'Spoqa Han Sans Neo';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            @include mobile {
              font-size: 12px;
            }
          }

          &:hover {
            border-radius: 12px;
            border: 1px solid rgba(25, 24, 24, 0.6);
            background: #cecece;
          }
        }
      }
    }

    .submitted_container {
      width: 520px;
      height: 520px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      gap: 80px;

      p {
        color: #121212;
        text-align: center;
        font-family: 'Spoqa Han Sans Neo';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .button {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 91px;
        height: 43px;
        flex-shrink: 0;
        border-radius: 30px;
        border: 1px solid #a0a0a0;
        background: #e5e5e5;

        color: #5e5e5e;
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        cursor: pointer;

        &:hover {
          border-radius: 30px;
          border: 1px solid rgba(18, 18, 18, 0.6);
          background: #cecece;

          color: #121212;
          font-family: Pretendard;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        &:active {
          border-radius: 30px;
          border: 1px solid rgba(18, 18, 18, 0.8);
          background: #bdbdbd;

          color: #121212;
          font-family: Pretendard;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }
}
