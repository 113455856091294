body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Pretendard';
  overflow-x: hidden;
  overscroll-behavior: none;

  background-color: #111;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Julius Sans One';
  src: url('./assets/fonts/JuliusSansOne-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./assets/fonts/Pretendard-Thin.ttf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./assets/fonts/Pretendard-ExtraLight.ttf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./assets/fonts/Pretendard-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./assets/fonts/Pretendard-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./assets/fonts/Pretendard-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./assets/fonts/Pretendard-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./assets/fonts/Pretendard-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./assets/fonts/Pretendard-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./assets/fonts/Pretendard-Black.ttf');
  font-weight: 900;
  font-style: normal;
}

/*
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src: url('./assets/fonts/Poppins-Thin.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: url('./assets/fonts/Poppins-ExtraLight.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/Poppins-Light.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Poppins-Medium.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/Poppins-Bold.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url('./assets/fonts/Poppins-ExtraBold.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url('./assets/fonts/Poppins-Black.ttf');
}
*/
@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/SpoqaHanSansNeo-Bold.otf');
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/SpoqaHanSansNeo-Medium.otf');
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/SpoqaHanSansNeo-Regular.otf');
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/SpoqaHanSansNeo-Light.otf');
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 200;
  src: url('./assets/fonts/SpoqaHanSansNeo-Thin.otf');
} 



.App {
  height: 100%;
  display: block;
}
